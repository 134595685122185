import env = require("./env");

export interface ITrackMetadata {
    readonly artist: string;
    readonly title: string;
    readonly url: string;
    readonly shid: number;
    coverartUrl(fallback: boolean): string;
}

export function createMetadata(artist: string, title: string, shid?: number, googleFallback?: boolean): ITrackMetadata {
    artist = texturize(artist);
    title = texturize(title);
    shid = shid || 0;

    function coverartUrl(fallback: boolean) {
        if (!shid && !fallback) {
            return "";
        }
        let url = `https://cafe-static.amartynov.ru/coverart/${shid}.jpg`;
        if (!fallback) {
            url += "?r=1";
        }
        return url;
    }

    return {
        artist,
        title,
        url: formatUrl(artist, title, shid, googleFallback),
        shid,
        coverartUrl
    };
}

function texturize(text: string) {
    return text
        .replace(/\.{3,}/g, "\u2026")
        .replace(/'/g, "\u2019")
        .replace(/(^|\s)"(\S)/g, "$1\u201c$2")
        .replace(/(\S)"($|\s|,)/g, "$1\u201d$2");
}

function formatUrl(artist: string, title: string, shid: number, googleFallback: boolean) {
    if (!shid) {
        return googleFallback
            ? "https://www.google.com/search?q=" + encodeURIComponent(artist + " - " + title)
            : null;
    }

    const urlBuilder = [ "https://www.shazam.com/" ] as (string|number)[];
    const useShareHtmlUrl = !ANDROID_APP && !env.mobile;

    if (useShareHtmlUrl) {
        urlBuilder.push("snippets/email-share/");
    } else {
        urlBuilder.push("track/");
    }

    urlBuilder.push(shid);

    if (useShareHtmlUrl) {
        urlBuilder.push("?lang=en&country=US");
    } else {
        urlBuilder.push("?co=US");
    }

    return urlBuilder.join("");
}
